import React, { FC } from 'react'
import styled, { css } from 'styled-components'
import FrozenGoodsIcon from 'icons/frozenGoodsIcon.svg'

export type LabelValue = 'Nýtt' | 'Væntanlegt' | 'Frostvara' | 'Tilboð' | 'Sérvara'

interface LabelProps {
	name: LabelValue
	isFrostLabel?: boolean
}

const StyledProductLabel = styled.div<LabelProps>`
	align-items: center;
	height: 3rem;
	padding: 0 1.6rem;
	background-color: ${({ theme }) => theme.colors.primary};
	color: #fff;
	font-weight: 700;
	border-radius: 2rem;
	display:inline-flex;
	margin-right: 1rem;
	margin-top: 0;
	font-size: 1.4rem;

	${({ name, theme }) =>
		name === 'Væntanlegt' &&
		css`
			background-color: ${theme.colors.grayDark};
		`}
	${({ name, theme }) =>
		name === 'Sérvara' &&
		css`
			background-color: ${theme.colors.textDark};
		`}
	${({ name, theme }) =>
		name === 'Nýtt' &&
		css`
			background-color: ${theme.colors.primary};
		`}
		${({ name, theme }) =>
			name === 'Tilboð' &&
			css`
				background-color: ${theme.colors.secondary};
			`}
	${({ name, theme }) =>
		name === 'Frostvara' &&
		css`
			svg {
				margin: auto:
				display: block;
			}
			width: 3rem;
			background-color: ${theme.colors.primaryLight};
			border-radius: 100%;
			padding: 0;
		`}
`

const StyledIconLabel = styled.div`
	display: inline-flex;
	align-items: center;
	justify-content: center;
	background-color: ${({ theme }) => theme.colors.primaryLight};
	width: 3rem;
	height: 3rem;
	border-radius: 100%;
	margin-right: 1rem;
`

export const ProductLabel: FC<LabelProps> = ({ name }) =>
	name === 'Frostvara' ? (
		<StyledIconLabel>
			<FrozenGoodsIcon />
		</StyledIconLabel>
	) : (
		<StyledProductLabel key={name} name={name}>
			{name}
		</StyledProductLabel>
	)
