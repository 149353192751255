import React, { FC } from 'react'
import ContentLoader from 'react-content-loader'

const ProductBoxLoader: FC<{}> = () => {
	return (
		<ContentLoader
			speed={2}
			width={282}
			height={440}
			viewBox="0 0 282 440"
			backgroundColor="#f3f3f3"
			foregroundColor="#ecebeb">
			<rect x="0" y="0" rx="3" ry="3" width="282" height="230" />
			<rect x="26" y="260" rx="0" ry="0" width="199" height="19" />
			<rect x="26" y="286" rx="0" ry="0" width="120" height="14" />
			<rect x="26" y="318" rx="0" ry="0" width="116" height="14" />
			<rect x="26" y="340" rx="0" ry="0" width="84" height="14" />
			<circle cx="36" cy="400" r="10" />
			<rect x="56" y="395" rx="0" ry="0" width="80" height="10" />
		</ContentLoader>
	)
}

export default ProductBoxLoader
