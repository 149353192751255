import styled, { css } from 'styled-components'

interface WrapperProps {
	hasOverlay?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
	display: flex;
	align-items: center;
	pointer-events: auto;
	display: flex;
	transition: color 250ms ease;
	cursor: pointer;

	&:hover {
		p {
			color: ${({ theme }) => theme.colors.primary};
		}
	}

	svg {
		height: 3rem;
		width: 3rem;
		margin: 0 !important; //!important to override Toastify styles

		${({ hasOverlay }) =>
			hasOverlay &&
			css`
				path:nth-child(2) {
					fill: none;
				}
			`}
	}
`

export const Text = styled.p`
	margin-left: 1rem;
	font-weight: 700;
	font-size: 1.4rem;
	color: ${({ theme }) => theme.colors.textDark};
`
