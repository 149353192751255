import React, { FC, useState } from 'react'
import { animated, useSpring, config } from 'react-spring'
import styled from 'styled-components'

const StyledTooltipWrapper = styled.div`
	position: relative;
`

interface TooltipProps {
	offsetbottom?: string
	dark?: boolean
}

const StyledTooltip = styled(animated.div)<TooltipProps>`
	position: absolute;
	bottom: ${({ offsetbottom }) => (offsetbottom ? `calc(100% + ${offsetbottom})` : 'calc(100% + 1.5rem)')};
	left: -2rem;
	width: 15rem;
	padding: 0.6rem 1.5rem;
	border-radius: 16px;
	background-color: ${({ dark, theme }) => (dark ? theme.colors.textDark : theme.colors.green)};
	color: #fff;
	font-size: 1.2rem;
	font-weight: 800;
	line-height: 1.5;
	text-align: center;
	box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.1);
	pointer-events: none;

	&:after {
		content: '';
		position: absolute;
		bottom: -0.5rem;
		left: 3rem;
		width: 1rem;
		height: 1rem;
		transform: rotate(45deg);
		background-color: ${({ dark, theme }) => (dark ? theme.colors.textDark : theme.colors.green)};
		z-index: -1;
	}
`

interface Props {
	tooltip: string
	offsetbottom?: string
	dark?: boolean
}

const WithTooltip: FC<Props> = ({ tooltip, offsetbottom, dark = false, children }) => {
	const [active, setActive] = useState(false)
	const handleMouseEnter = () => setActive(true)
	const handleMouseLeave = () => setActive(false)

	const props = useSpring({
		opacity: active ? 1 : 0,
		transform: active ? 'translate3d(0, 0%, 0)' : 'translate3d(0, 100%, 0)',
		config: config.wobbly,
	})

	return (
		<StyledTooltipWrapper aria-label={tooltip} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave}>
			<StyledTooltip style={props} dark={dark} offsetbottom={offsetbottom}>
				<div>{tooltip}</div>
			</StyledTooltip>
			{children}
		</StyledTooltipWrapper>
	)
}

export default WithTooltip
