import StorefrontProduct from '@local-types/products/storefrontProduct'
import WithLargeTooltip from 'components/withTooltip/withLargeTooltip'
import { ProductLabel } from './productLabel'

interface Props {
	product: StorefrontProduct
}

export const CustomProductLabel = ({ product }: Props) => {
	return product.customProductMessage ? (
		<WithLargeTooltip tooltip={product.customProductMessage} dark>
			<ProductLabel name="Sérvara" />
		</WithLargeTooltip>
	) : product.customProduct ? (
		<ProductLabel name="Sérvara" />
	) : null
}
