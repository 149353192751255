import { MeasurementUnitCode, AllergenCode, NutrientTypeCode, BrandName } from '@local-types/products/codes'
import Celery from 'icons/celery.svg'
import Crab from 'icons/crab.svg'
import Egg from 'icons/egg.svg'
import Fish from 'icons/fish.svg'
import Grain from 'icons/grain.svg'
import Milk from 'icons/milk.svg'
import Mustard from 'icons/mustard.svg'
import Nuts from 'icons/nuts.svg'
import Peas from 'icons/peas.svg'
import Sesame from 'icons/sesame.svg'
import Snail from 'icons/snail.svg'
import Wheat from 'icons/wheat.svg'

type MeasurementUnitCodeMapper = Record<MeasurementUnitCode, string>

const measurementUnitCodeMapper: MeasurementUnitCodeMapper = {
	'1N': 'stk',
	CLT: 'cl',
	CMT: 'cm',
	DLT: 'dl',
	G21: 'bolli',
	GRM: 'g',
	KGM: 'kg',
	LTR: 'l',
	MTR: 'm',
	KJO: 'kJ',
	E14: 'kkal',
}

export const getReadableMeasurementFromCode = (code: MeasurementUnitCode) => {
	return measurementUnitCodeMapper[code] || code
}

type AllergenUnitCodeMapper = Record<AllergenCode, string>

const allergenUnitCodeMapper: AllergenUnitCodeMapper = {
	AW: 'Kornvörur (glúten)',
	NE: 'Baunir',
	AF: 'Fiskur',
	GK: 'Kornvörur (glúten)',
	GO: 'Hafrar (glúten)',
	ML: 'Laktósi',
	NL: 'Lúpínur',
	AM: 'Mjólkurvörur',
	AE: 'Egg',
	UM: 'Lindýr',
	GB: 'Bygg (glúten)',
	AU: 'Súlfít',
	NR: 'Rúgur (glúten)',
	AS: 'Sesamfræ',
	BC: 'Sellerí',
	BM: 'Sinnep',
	AY: 'Soja',
	GS: 'Spelt',
	UW: 'Hveiti (glúten)',
	AC: 'Krabbadýr',
	SA: 'Möndlur',
	SE: 'Smjörhnetur',
	SR: 'Brasilískar hnetur',
	SC: 'Kasjúhnetur',
	SN: 'Kastaníuhnetur',
	SH: 'Heslihnetur',
	SI: 'Harðhnetur',
	SM: 'Makademíuhnetur',
	AP: 'Jarðhnetur',
	SP: 'Pekanhnetur',
	ST: 'Pistasíuhnetur',
	SJ: 'Shea hnetur',
	SQ: 'Queensland hnetur',
	AN: 'Trjáhnetur',
	SW: 'Valhnetur',
}

export const getReadableAllergenFromCode = (code: AllergenCode) => {
	return allergenUnitCodeMapper[code] || code
}

const allergenIconCodeMapper: AllergenUnitCodeMapper = {
	AW: Wheat,
	NE: Peas,
	AF: Fish,
	GK: Grain,
	GO: Grain,
	ML: Milk,
	NL: Wheat,
	AM: Milk,
	AE: Egg,
	UM: Snail,
	GB: Wheat,
	AU: Wheat,
	NR: Wheat,
	AS: Sesame,
	BC: Celery,
	BM: Mustard,
	AY: Peas,
	GS: Wheat,
	UW: Grain,
	AC: Crab,
	SA: Nuts,
	SE: Nuts,
	SR: Nuts,
	SC: Nuts,
	SN: Nuts,
	SH: Nuts,
	SI: Nuts,
	SM: Nuts,
	AP: Nuts,
	SP: Nuts,
	ST: Nuts,
	SJ: Nuts,
	SQ: Nuts,
	AN: Nuts,
	SW: Nuts,
}

export const getAllergenIconFromCode = (code: AllergenCode) => {
	return allergenIconCodeMapper[code] || allergenIconCodeMapper['AW']
}

type NutrientCodeMapper = Record<NutrientTypeCode, string>

export const nutrientCodeMapper: NutrientCodeMapper = {
	BIOT: 'Bíótín',
	CA: 'Kalsíum',
	CHOAVL: 'Kolvetni',
	CLD: 'Klóríð',
	CR: 'Krómíum',
	CU: 'Kopar',
	'ENER-': 'Orka',
	ENERSF: 'Kaloríur frá mettaðri fitu',
	FAMSCIS: 'Þar af einómettuð fita',
	FAPUCIS: 'Þar af fjölómettuð fita',
	FASAT: 'Þar af mettuð fita',
	FAT: 'Fita',
	FD: 'Flúóríð',
	FE: 'Járn',
	FIBTG: 'Trefjar',
	FOLDFE: 'Fólínsýra',
	G_CMO: 'Kolmónoxíð',
	G_HC: 'Bíkarbónat',
	G_NICT: 'Nikótín',
	G_NMES: 'Non-milki extrinsic sugars',
	G_TAR: 'Tjara',
	GINSENG: 'Ginseng',
	HMB: 'Beta-hýdroxý-beta-metýlbútýrat',
	ID: 'Joðíð',
	IODIZED_SALT: 'Joðað salt',
	K: 'Kalíum',
	L_CARNITINE: 'Karnitín',
	MG: 'Magnesíum',
	MN: 'Mangan',
	MO: 'Molybden',
	NA: 'Sódíum',
	NACL: 'NACL',
	NIA: 'Níasín',
	NUCLEOTIDE: 'Beta-hýdroxý-beta-metýlbútýrat',
	P: 'Fosfór',
	PANTAC: 'Pantóþensýra',
	POLYL: 'Þar af pólýólum',
	'PRO-': 'Prótein',
	RIBF: 'Ríbóflavín',
	SALTEQ: 'Salt',
	SE: 'Seleníum',
	STARCH: 'Þar af sterkja',
	SUGAR: 'Þar af ónotaður sykur',
	'SUGAR-': 'Þar af sykurtegundir',
	THIA: 'Thiamin',
	'VITA-': 'A-vítamín',
	VITB12: 'B12-vítamín',
	'VITB6-': 'B-6 vítamín',
	'VITC-': 'C-vítamín',
	'VITD-': 'D-vítamín',
	'VITE-': 'E-vítamín',
	VITK: 'K-vítamín',
	X_FIBTPE: 'Þar af trefjar',
	ZN: 'Sink',
}

export const getReadableNutrientFromCode = (code: NutrientTypeCode) => {
	return nutrientCodeMapper[code] || code
}

type BrandImageWrapper = Record<BrandName, string>

const brandImageWrapper: BrandImageWrapper = {
	SS: '/images/brands/ss.jpg',
	Búrfell: '/images/brands/burfell.jpg',
	'1944': '/images/brands/1944.jpg',
	Mars: '/images/brands/mars.jpg',
	McCormick: '/images/brands/mccormick.jpg',
	Borges: '/images/brands/borges.jpg',
	OrientalMerchant: '/images/brands/oriental-merchant.jpg',
	Franks: '/images/brands/franks.jpg',
	Barilla: '/images/brands/barilla.jpg',
	Mutti: '/images/brands/mutti.jpg',
	Scandia: '/images/brands/Scandia.jpg',
	Opies: '/images/brands/opies.jpg',
	Whiskas: '/images/brands/whiskas.jpg',
	Pedigree: '/images/brands/pedigree.jpg',
	UncleBens: '/images/brands/unclebens.jpg',
	Reykjagarður: '/images/brands/hollta.jpg',
	'Hollt og Gott': '/images/brands/holltoggott-small.png',
	Snickers: '/images/brands/snickers.jpg',
	'M&M': '/images/brands/mm.jpg',
	'Menz & Gasser S.P.A.': '/images/brands/menzgasserlogo.png',
	Bounty: '/images/brands/bounty.jpg',
	Celebrations: '/images/brands/celebrations.jpg',
	ChefsWorld: '/images/brands/chefsworld.jpg',
	CocoLopez: '/images/brands/cocolopez.jpg',
	CountryRange: '/images/brands/country-range.png',
	Dolmio: '/images/brands/dolmio.jpg',
	Dove: '/images/brands/dove.jpg',
	Dreamies: '/images/brands/dreamies.jpg',
	French: '/images/brands/french.jpg',
	HuyFong: '/images/brands/huy-fong.jpg',
	Kikkoman: '/images/brands/kikkoman.jpg',
	Lawrys: '/images/brands/lawrys.jpg',
	LeeKumKee: '/images/brands/leekumkee.jpg',
	Linghams: '/images/brands/linghams.jpg',
	Maltesers: '/images/brands/maltesers.jpg',
	MayPloy: '/images/brands/mae-ploy.jpg',
	MilkyWay: '/images/brands/milkyway.jpg',
	Obento: '/images/brands/obento.jpg',
	Ortalli: '/images/brands/ortalli.jpg',
	PerfectFit: '/images/brands/perfectfit.jpg',
	Ringo: '/images/brands/ringo.jpg',
	Schwartz: '/images/brands/schwartz.jpg',
	Sheba: '/images/brands/sheba.jpg',
	Stubbs: '/images/brands/stubbs.jpg',
	ThaiKitchen: '/images/brands/thaikitchen.jpg',
	Twix: '/images/brands/twix.jpg',
	Valleydays: '/images/brands/valleydays.jpg',
	Voiello: '/images/brands/voiello.jpg',
	Wasa: '/images/brands/wasa.jpg',
	'DRYK ApS': '/images/brands/dryk-small.png',
	Greens: '/images/brands/greens-small.png',
}

export const getBrandImageFromName = (name: BrandName, sku: string) => {
	// Edge case: Greens product sku starts with 6009
	return sku.startsWith('6009') ? brandImageWrapper['Greens'] : brandImageWrapper[name] || '/images/brands/ss.jpg'
}
