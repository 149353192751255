import WithTooltip from 'components/withTooltip/withTooltip'
import useTypedSelector from 'interfaces/useTypedSelector'
import React, { FC } from 'react'
import * as StyledListButton from './addToListButton.styles'
import IconHeart from 'icons/heart-plus.svg'
import { IconButton, StrippedButton } from '../base/buttons/buttons'
import { setActiveModal } from '../../reducers/modalReducer'
import { useDispatch } from 'react-redux'
import { setActiveProductId } from '../../reducers/productListReducer'
import useActiveCompany from '../../hooks/useActiveCompany'

interface Props {
	productId: string
	withText?: boolean
	hasOverlay?: boolean
}

export const AddToListButton: FC<Props> = ({ withText, productId, hasOverlay }) => {
	const { authState } = useTypedSelector(({ auth }) => auth)
	const dispatch = useDispatch()
	const { activeRelation } = useActiveCompany()

	if (authState !== 'loggedIn') return null

	const handleOnClick = () => {
		if (!activeRelation) {
			dispatch(setActiveModal('no-active-relation'))
			return
		}
		dispatch(setActiveProductId(productId))
		dispatch(setActiveModal('add-to-product-list'))
	}

	return (
		<>
			{withText ? (
				<StyledListButton.Wrapper>
					<StrippedButton onClick={handleOnClick}>
						<IconHeart />
					</StrippedButton>
					<StyledListButton.Text>Setja í lista</StyledListButton.Text>
				</StyledListButton.Wrapper>
			) : (
				<WithTooltip tooltip="Setja í lista" offsetbottom="2.5rem">
					<StyledListButton.Wrapper hasOverlay={hasOverlay}>
						<StrippedButton onClick={handleOnClick}>
							<IconHeart />
						</StrippedButton>
					</StyledListButton.Wrapper>
				</WithTooltip>
			)}
		</>
	)
}
