import React, { FC, useState } from 'react'
import * as StyledProductBox from './productBox.styles'
import {
	getCroppedProdImg,
	shouldUseCustomerPrice,
	shouldUseSpecialPrice,
	renderProductCustPrice,
	renderProductListPrice,
	isSoldInPackages,
	isPackageSoldInUnits,
	getLabels,
	hasSpecialPrice,
	getMinimumUnitInPackage,
} from '../../../../helpers/productHelpers/productHelpers'
import StorefrontProduct from '@local-types/products/storefrontProduct'
import Link from 'next/link'
import { getBrandImageFromName } from 'helpers/mappers/mappers'
import StandardBasketButton from 'components/products/standardBasketButton'
import useBasket from 'hooks/useBasket'
import { SwitchLabel, switchStyles } from 'components/base/forms/input'
import Switch from 'react-switch'
import { Medium } from 'components/base/typography/misc'
import FrozenGoodsIcon from 'icons/frozenGoodsIcon.svg'
import AddToListButton from 'components/addToListButton'

export type LabelValue = 'Tilboð' | 'Nýtt' | 'Væntanlegt' | 'Frostvara'
import { ProductLabel } from '../../productLabel/productLabel'
import WithLargeTooltip from 'components/withTooltip/withLargeTooltip'
import { CustomProductLabel } from 'components/products/productLabel/customProductLabel'

interface Props {
	product: StorefrontProduct
}

const ProductBox: FC<Props> = ({ product }) => {
	const { getBasketProduct } = useBasket()
	const basketProduct = getBasketProduct(product.id)
	const [packageChecked, setPackageChecked] = useState(
		basketProduct ? basketProduct.isPackage : isSoldInPackages(product.attributes)
	)
	const [kgChecked, setKgChecked] = useState(basketProduct?.selectedUnit === 'kg' || false)

	const labels = getLabels(product)
	const isOnOffer = hasSpecialPrice(product?.price)
	const minimumUnit = getMinimumUnitInPackage(product.attributes)

	const handlePackageCheck = () => setPackageChecked(!packageChecked)
	const handleKgCheck = () => setKgChecked(!kgChecked)
	return (
		<StyledProductBox.Wrapper isOnOffer={isOnOffer}>
			<StyledProductBox.ListButtonWrapper>
				<AddToListButton productId={product.id} hasOverlay={basketProduct !== null} />
			</StyledProductBox.ListButtonWrapper>
			{isOnOffer && <StyledProductBox.OfferLabel>Tilboð</StyledProductBox.OfferLabel>}
			<Link href={`/vara/${product.slug}`}>
				<StyledProductBox.WrapperLink href={`/vara/${product.slug}`}>
					<StyledProductBox.ProductOverlayWrapper>
						{basketProduct && (
							<StyledProductBox.ProductBasketOverlay>
								{`${basketProduct.quantity} ${basketProduct.selectedUnit}. í körfu`}
							</StyledProductBox.ProductBasketOverlay>
						)}
						<StyledProductBox.TopButtonsWrapper>
							<StyledProductBox.ProductBrandImage
								src={getBrandImageFromName(product.attributes.brandName, product.sku)}
								alt={product.attributes.brandName}
							/>
						</StyledProductBox.TopButtonsWrapper>
						<StyledProductBox.ProductImageWrapper>
							<StyledProductBox.ProductImage
								src={getCroppedProdImg(
									product.images[0]?.url || 'https://ss.datadwell.com/clients/ss/product_id/OP9019/600/500/0/image.png',
									180,
									180
								)}
								alt={product.images[0]?.altText || product.name}
							/>
							<StyledProductBox.ProductLabels>
								{labels?.map(label => (
									<ProductLabel key={label} name={label} />
								))}
								<CustomProductLabel product={product} />
							</StyledProductBox.ProductLabels>
						</StyledProductBox.ProductImageWrapper>
					</StyledProductBox.ProductOverlayWrapper>
					<StyledProductBox.ProductContent pb="0">
						<StyledProductBox.ProductTitle>{product.name}</StyledProductBox.ProductTitle>
						<StyledProductBox.ProductSkuWrapper>
							Vörunúmer: <StyledProductBox.StyledSku>{' ' + product.sku}</StyledProductBox.StyledSku>
						</StyledProductBox.ProductSkuWrapper>

						{product?.price && (
							<>
								{product.price.custPriceWithVAT && product.price.custPriceWithVAT !== 0 ? (
									<>
										{shouldUseCustomerPrice(product.price) && (
											<StyledProductBox.ProductSpecialPrice inactive={shouldUseSpecialPrice(product.price)}>
												Þitt verð: {renderProductCustPrice(product.price, product.unit)}
											</StyledProductBox.ProductSpecialPrice>
										)}
									</>
								) : null}

								{product.price.listPriceWithVAT !== 0 ? (
									<>
										<StyledProductBox.ProductListPrice inactive={shouldUseCustomerPrice(product.price)}>
											Listaverð: {renderProductListPrice(product.price, product.unit)}
										</StyledProductBox.ProductListPrice>
									</>
								) : null}
							</>
						)}
					</StyledProductBox.ProductContent>
				</StyledProductBox.WrapperLink>
			</Link>
			{isPackageSoldInUnits(product.attributes) && (
				<StyledProductBox.ProductContent pt="0">
					<SwitchLabel mt="1rem" justifyContent="space-between">
						<Medium>Versla pakkningar</Medium>
						<Switch
							{...switchStyles}
							handleDiameter={18}
							height={20}
							width={40}
							onChange={handlePackageCheck}
							checked={packageChecked}
						/>
					</SwitchLabel>
				</StyledProductBox.ProductContent>
			)}
			{product.unit === 'kg' && (
				<StyledProductBox.ProductContent pt="0">
					<SwitchLabel mt="1rem" justifyContent="space-between">
						<Medium>Versla í kílóatali</Medium>
						<Switch
							{...switchStyles}
							handleDiameter={18}
							height={20}
							width={40}
							onChange={handleKgCheck}
							checked={kgChecked}
						/>
					</SwitchLabel>
				</StyledProductBox.ProductContent>
			)}
			<StyledProductBox.BasketButtonWrapper>
				<StandardBasketButton
					product={product}
					basketProduct={basketProduct}
					kgSelected={kgChecked}
					packageSelected={packageChecked}
				/>
			</StyledProductBox.BasketButtonWrapper>
			{isSoldInPackages(product.attributes) && !isPackageSoldInUnits(product.attributes) && (
				<StyledProductBox.ProductPackageInfo>
					<StyledProductBox.Strong>Ath:</StyledProductBox.Strong> lágmarksmagn {minimumUnit} {product.unit}.
				</StyledProductBox.ProductPackageInfo>
			)}
		</StyledProductBox.Wrapper>
	)
}

export default ProductBox
