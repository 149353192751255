import { media } from 'constants/media/media'
import { zIndex } from 'constants/zIndex/zIndex'
import styled, { css } from 'styled-components'

export const WrapperLink = styled.a`
	cursor: pointer;
	color: ${({ theme }) => theme.colors.textDark};
	height: 100%;
`

interface WrapperProps {
	isOnOffer?: boolean
}

export const Wrapper = styled.div<WrapperProps>`
	-webkit-user-select: none; /* Chrome/Safari */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* IE10+ */

	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
	width: 100%;
	height: 100%;
	background-color: #fff;
	border: 1px solid ${({ theme, isOnOffer }) => (isOnOffer ? theme.colors.secondary : theme.colors.borderColor)};
	border-radius: 4px;
	transition: border-color 200ms, box-shadow 200ms;

	&:hover {
		border-color: ${({ theme, isOnOffer }) => (isOnOffer ? theme.colors.secondaryLight : 'transparent')};
		box-shadow: 0px 24px 50px rgba(0, 0, 0, 0.07);
	}
`

export const TopButtonsWrapper = styled.div`
	display: flex;
	justify-content: space-between;
	padding-top: 2rem;
	padding-left: 2.5rem;
`

export const ListButtonWrapper = styled.div`
	position: absolute;
	top: 0;
	right: 0;
	padding: 2rem 3rem;
	z-index: ${zIndex.listButton};
`

export const ProductImageWrapper = styled.div`
	position: relative;
	width: 100%;
	padding-top: 80%;
	border-bottom: 1px solid ${({ theme }) => theme.colors.borderColor};
`

export const ProductBasketOverlay = styled.div`
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: ${({ theme }) => theme.colors.boxOverlay};
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${({ theme }) => theme.colors.white};
	font-weight: bold;
	font-size: 2rem;
	z-index: 3;
`

export const ProductOverlayWrapper = styled.div`
	position: relative;
`

export const ProductImage = styled.img`
	position: absolute;
	top: 50%;
	left: 50%;
	transform: translate3d(-50%, -50%, 0);
	max-height: 100%;
`

export const ProductBrandImage = styled.img`
	max-width: 6rem;
	max-height: 2.5rem;
	z-index: 2;
	${media.mobileOnly`
		left: 4rem;
	`};
`

interface LabelsProps {
	pushLeft?: boolean
}

export const ProductLabels = styled.div<LabelsProps>`
	position: absolute;
	bottom: -1.5rem;
	right: ${({ pushLeft }) => (pushLeft ? '8rem' : '0.5rem')};
	display: flex;
	z-index: 4;
	display: flex;
	justify-content: center;
	align-items: center;
`

export const ProductPackage = styled.div`
	padding: 0 3rem;
`

interface ContentProps {
	pt?: string
	pb?: string
}

export const ProductContent = styled.div<ContentProps>`
	display: flex;
	flex-direction: column;
	padding: 0 3rem;
	padding-top: ${({ pt }) => pt || '2.5rem'};
	padding-bottom: ${({ pb }) => pb || '2.5rem'};
	${media.mobileOnly`
		padding: 2rem 3.5rem;
	`};
`

export const ProductTitle = styled.div`
	font-weight: 700;
	font-size: 1.8rem;
	line-height: 1.33;
	margin-bottom: 0.3rem;
`

export const ProductSkuWrapper = styled.div`
	display: flex;
	width: 100%;
	color: ${({ theme }) => theme.colors.textDark};
	font-size: 1.4rem;
	line-height: 1.3;
	margin-bottom: 1.2rem;
	font-weight: 600;
`
export const StyledSku = styled.div`
	color: ${({ theme }) => theme.colors.textLight};
	margin-left: 0.5rem;
`

interface InactiveProps {
	inactive: boolean
}

export const ProductSpecialPrice = styled.div<InactiveProps>`
	font-size: 1.6rem;
	line-height: 2.6rem;
	font-weight: 700;
	color: ${({ theme }) => theme.colors.secondary};

	${({ inactive }) =>
		inactive &&
		css`
			font-weight: 400;
			font-size: 1.4rem;
			color: ${({ theme }) => theme.colors.textColor};
			line-height: 1.3;
			text-decoration: line-through;
		`}
`

export const ProductListPrice = styled.div<InactiveProps>`
	color: ${({ theme }) => theme.colors.textDark};
	font-size: 1.6rem;
	line-height: 2.6rem;
	font-weight: 700;

	${({ inactive }) =>
		inactive &&
		css`
			font-weight: 400;
			font-size: 1.4rem;
			color: ${({ theme }) => theme.colors.textColor};
			line-height: 1.3;
			text-decoration: line-through;
		`}
`

export const BasketButtonWrapper = styled.div``

export const OfferLabel = styled.div`
	background-color: ${({ theme }) => theme.colors.secondary};
	height: 2.5rem;
	width: 7.2rem;
	border-radius: 4px 4px 0px 0px;
	font-size: 12px;
	display: flex;
	align-items: center;
	padding: 0 1.6rem;
	color: #fff;
	font-weight: 600;
	position: absolute;
	z-index: 1;
	top: -2.5rem;
	margin-left: auto;
	margin-right: auto;
	left: 0;
	right: 0;
`

export const ProductPackageInfo = styled.div`
	background-color: ${({ theme }) => theme.colors.grayBlue};
	border-top: 1px solid #ebf0f6;
	text-align: center;
	font-size: 14px;
	padding: 8px 0;
	line-height: 1;
	border-bottom-left-radius: 4px;
	border-bottom-right-radius: 4px;
`

export const Strong = styled.span`
	color: #000000;
	font-weight: 500;
`
